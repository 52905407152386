.default-container {
  &.page-dashboard {
    padding: 0 !important;

    .customWrapper {
      height: 100%;

      .noBorder {
        border-width: 0px;
      }

      .cardStyle {
        margin-top: 15px;
        margin-right: 10px;
      }

      .fontBold {
        font-weight: bold;
        font-size: 13px;
      }

      .colorGray {
        color: #a1a1a1;
      }

      .overlayStyles {
        background-color: #080808;
        border: 0;
        border-radius: 8px;
        color: #FFF;
        margin-left: -50%;
        margin-top: -180px;
        padding: 8px;
        width: 300px;

        .ant-card-body {
          padding: 0;

          h1,
          h4 {
            color: #FFF;
            line-height: 1;
          }

          h1 {
            font-size: 1.2em;
            margin-bottom: 10px;
          }

          h4 {
            font-size: 1em;
            margin-bottom: 5px;
          }
        }

        &::after {
          content: ' ';
          position: absolute;
          right: calc(50% - 15px);
          bottom: -15px;
          border-top: none;
          border-top: 15px solid #080808;
          border-right: 15px solid transparent;
          border-left: 15px solid transparent;
          border-bottom: none;
        }
      }

      .map-content {
        position: relative;

        .center-wrapper {
          left: 9px;
          position: absolute;
          bottom: 32px;
          z-index: 9;

          .ant-btn {
            font-size: 16px;
            height: auto;
          }
        }
      }
    }
  }
}

.ant-tabs-content {
  .user-groups-list {
    max-height: 400px;
    overflow: auto;
  }
}

.mdl-read-only {
  .ant-modal-content {
    .ant-modal-footer .ant-btn-primary {
      display: none;
    }
  }
}