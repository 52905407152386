.header-dashboard {
  align-items: center;
  background: @component-background;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .logo-wrapper {
    height: 100%;
    width: auto;

    .logo {
      height: 100%;
    }
  }

  .trigger,
  .logout-trigger {
    font-size: 20px;
  }

  .menu {
    height: 100%;
    padding-top: 8px;
    margin-left: 20px;
    min-width: 450px;
    width: auto;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      background: transparent !important;
      color: #fff !important;
      font-weight: bold;
    }

    .ant-menu-item:hover,
    .ant-menu-submenu :hover {
      color: #fff !important;
      opacity: 1;
    }

    .ant-menu-item,
    .ant-menu-submenu {
      color: #fff;
      opacity: 0.7;
    }
  }
}
