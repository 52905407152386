.ant-layout {
  flex-wrap: wrap;
  height: 100vh;
  justify-content: stretch;

  .default-container {
    background-color: #fff;
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 24px;

    .wrapper {
      flex: 1;
      height: 100%;

      .content {
        flex: 1;
        height: 100%;
      }
    }

    .catalog-layout {
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
      overflow: auto;
    }

    .search-input {
      border-radius: 10px;
      height: 40px;
      width: 500px;

      .anticon {
        color: #000;
        opacity: 0.2;
      }
    }

    .select-category {
      border-radius: 10px;
      height: 40px;
      width: 200px;

      .ant-select-selector {
        border-radius: 10px;
        height: 40px;
      }
    }

    .image-container {
      .ant-image {
        max-width: 60px;
      }
    }
  }
}