.login-screen {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100vw;

  .wrapper {
    overflow: hidden;
    padding: 20px;
    width: 20%;

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }

    .logo {
      margin-bottom: 30px;
      width: 100%;

      img {
        max-width: 100%;
      }
    }

    .login-form-button {
      width: 100%;
    }

    .sub-input {
      border: none;
      border-bottom: 1px solid;
      border-bottom-width: 1px !important;
      border-bottom-color: @primary-color;
    }

    .login-form-forgot {
      cursor: pointer;
    }
  }
  .cover {
    height: 100%;
    width: 80%;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    background-image: url('../../../Assets/img/cover.jpg');
    background-position: center;
    background-size: cover;
  }

  .version {
    bottom: 0;
    opacity: 0.7;
    position: absolute;
    right: 20px;
  }
}
