.menu-drawer-app {
  .ant-drawer-body {
    background-color: #222222;
  }
  .logo-wrapper {
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
    }
  }
  .ant-layout-sider {
    background: #222222;
  }
  .ant-menu-dark {
    background-color: #222222;
  }
  .ant-menu-inline.ant-menu-sub {
    background: #080808;
  }
  .ant-layout-sider-trigger {
    background: #080808;
  }
}
