.sidebar-app {
  background-color: #222222;

  .logo-wrapper {
    padding: 10px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .logout-trigger {
    color: @menu-dark-color;
    text-align: center;
    font-size: 18px;
  }

  .ant-menu-dark,
  .ant-layout-sider {
    background-color: #222222;
  }

  .ant-menu-inline.ant-menu-sub,
  .ant-layout-sider-trigger {
    background: #080808;
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
}
