.dynamic-table {
  table {
    thead {
      tr {
        th {
          background-color: #fff;
        }
      }
    }
  }
  .trigger {
    font-size: 13pt;
    color: #d3d0de;
    cursor: pointer;
    &:hover {
      color: #555555;
    }
  }
}
